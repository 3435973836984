import React, { useState } from 'react';
import { Formik } from 'formik';
import EventTitleWarningModal from './EventTitleWarningModal';
import { convertRawToHtml } from '../../../Utils/rteUtils';
import { formatEventStartDate } from '../../../Utils/dateUtils';
import {
  findEmail,
  findLink,
  findUrlOnly,
} from '../../../Utils/regexvalidation';
import {
  titleContainsEmail,
  titleContainsLink,
  descriptionContainsEmail,
  descriptionContainsLink,
} from '../../../Consts/validationErrorMessages';
import { emptyWYSIWYG } from '../../../Utils/htmlTagUtils';
import { convertToRaw, ContentState } from 'draft-js';

const stripTimezone = (date) => {
  if (!date) return date;
  if (typeof date === 'string') return new Date(date).toDateString();
  return date.toDateString();
};

const eventDescriptionToRaw = (description) => {
  switch (typeof description) {
    case 'string':
      return convertToRaw(ContentState.createFromText(description ?? ''));
    case 'null':
    case 'undefined':
      return convertToRaw(ContentState.createFromText(''));
    default:
      return description;
  }
};

const BasicInfoValidation = ({
  basicInfo,
  formRef,
  saveBasicInfo,
  isUpdate,
  disableButton,
  lastUpdateToEditor,
  ...props
}) => {
  const [titleWarning, setTitleWarning] = useState({
    openTitleWarningModal: false,
    titleWarningTouched: false,
  });

  return (
    <>
      <Formik
        initialValues={{
          eventId: basicInfo.eventId ?? '',
          eventTitle: basicInfo.eventTitle ?? '',
          eventType: basicInfo.eventType ?? 'regular',
          eventLocation: basicInfo.eventLocation ?? {
            place: '',
            city: '',
            country: '',
            lat: null,
            lng: null,
          },
          eventStartDate: formatEventStartDate(basicInfo),
          eventEndDate: basicInfo.eventEndDate ?? null,
          eventSaleStartDate: props.initReactivating
            ? null
            : basicInfo.eventSaleStartDate ?? new Date(),
          eventSaleEndDate: basicInfo.eventSaleEndDate ?? null,
          eventVideo: basicInfo.eventVideo ?? '',
          eventDescription: eventDescriptionToRaw(basicInfo.eventDescription),
          eventDescriptionPlain: basicInfo.eventDescription ?? '',
          eventDescriptionHtml: '',
          eventCurrency: basicInfo.eventCurrency ?? '',
          eventImages: basicInfo.eventImages ?? [],
          resultSubmissionStart: basicInfo.resultSubmissionStart ?? null,
          resultSubmissionEnd: basicInfo.resultSubmissionEnd ?? null,
          claimedEvent: false,
        }}
        validate={(values) => {
          const errors = {};
          if (!values.eventTitle) {
            errors.eventTitle = 'A name is required';
          }

          if (
            Object.keys(values.eventLocation).length === 0 ||
            !values.eventLocation.lat ||
            !values.eventLocation.lng
          ) {
            errors.eventLocation =
              'Please choose an event location from the suggestions';
          }

          if (!values.eventCurrency) {
            errors.eventCurrency = `Add the currency you want to use for your tickets set-up, reporting and invoicing. 
          Whichever you choose, participants will view prices in their chosen currency.
          Please note: Once the first ticket has been sold you will be unable to change the currency.`;
          }

          if (!values.eventStartDate) {
            errors.eventStartDate = 'Please add a date for your event';
          }
          if (!values.eventEndDate) {
            errors.eventEndDate = 'Please add an end date for your event';
          }

          if (!values.eventSaleStartDate) {
            errors.eventSaleStartDate =
              'Please add a date for ticket sales to begin';
          }

          if (!values.eventSaleEndDate) {
            errors.eventSaleEndDate =
              'Please add a date for ticket sales to end';
          }

          if (values.eventSaleStartDate >= values.eventStartDate) {
            errors.eventSaleStartDate =
              'The date to start ticket sales must be before the event start date';
          }

          if (values.eventSaleEndDate < values.eventSaleStartDate) {
            errors.eventSaleEndDate =
              'The date to end ticket sales must be after the sale start date';
          }

          if (values.eventSaleEndDate > values.eventEndDate) {
            errors.eventSaleEndDate =
              'The date to end ticket sales must be before the event end date';
          }

          if (
            values.eventDescription === null ||
            values.eventDescription === undefined ||
            values.eventDescription.length === 0
          ) {
            errors.eventDescription = 'Please add a description for your event';
          } else {
            const content = (() => {
              if (typeof values.eventDescription === 'string') {
                if (typeof JSON.parse(values.eventDescription) === 'string') {
                  return JSON.parse(values.eventDescription);
                }
                return values.eventDescription;
              }
              return JSON.stringify(values.eventDescription);
            })();
            if (emptyWYSIWYG(convertRawToHtml(content))) {
              errors.eventDescription =
                'Please add a description for your event';
            }
          }

          if (values.eventDescriptionPlain?.length > 5000) {
            errors.eventDescription =
              'The description must not exceed 5000 characters';
          }

          if (
            !!values.eventVideo &&
            !/http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?/.test(
              values.eventVideo
            )
          ) {
            errors.eventVideo = 'Invalid YouTube video link format';
          }

          if (findUrlOnly(values.eventTitle)) {
            errors.eventTitle = titleContainsLink;
          }

          if (findEmail(values.eventTitle)) {
            errors.eventTitle = titleContainsEmail;
          }

          if (findEmail(JSON.stringify(values.eventDescription))) {
            errors.eventDescription = descriptionContainsEmail;
          }
          if (
            !findEmail(JSON.stringify(values.eventDescription)) &&
            findLink(JSON.stringify(values.eventDescription))
          ) {
            errors.eventDescription = descriptionContainsLink;
          }

          if (
            values.eventType === 'virtual' &&
            (!values.resultSubmissionStart || !values.resultSubmissionEnd)
          ) {
            errors.resultSubmission =
              'Please add dates for runners to submit their results within';
          }

          if (
            !isUpdate &&
            !titleWarning.titleWarningTouched &&
            /\d/.test(values.eventTitle)
          ) {
            setTitleWarning({
              openTitleWarningModal: true,
              titleWarningTouched: true,
            });
            errors.eventTitle = 'Title contains a number';
          }

          if (!values.eventImages.length) {
            errors.eventImages = 'Please add some images for your event page';
          }

          return errors;
        }}
        innerRef={formRef}
        onSubmit={async (values, { resetForm }) => {
          let timediff = new Date().getTime() - lastUpdateToEditor.getTime();
          if (timediff < 2000) {
            await new Promise((r) => setTimeout(r, 1000 - timediff));
          }
          if (disableButton) disableButton(true);

          //convert description into html for EP
          values.eventDescriptionHtml = convertRawToHtml(
            JSON.stringify(values.eventDescription)
          );

          //Parse dates to string to ignore timezones. These are dealt with using the preset
          //timezone which is stored in the backend
          values.eventStartDateString = stripTimezone(values.eventStartDate);
          values.eventEndDateString = stripTimezone(values.eventEndDate);
          values.eventSaleStartDateString = stripTimezone(
            values.eventSaleStartDate
          );
          values.eventSaleEndDateString = stripTimezone(
            values.eventSaleEndDate
          );
          values.resultSubmissionStartString = stripTimezone(
            values.resultSubmissionStart
          );
          values.resultSubmissionEndString = stripTimezone(
            values.resultSubmissionEnd
          );

          await saveBasicInfo(values);
          resetForm({ values });
          if (disableButton) disableButton(false);
        }}
      >
        {props.children}
      </Formik>
      {titleWarning.openTitleWarningModal && (
        <EventTitleWarningModal
          open={titleWarning.openTitleWarningModal}
          handleClose={() =>
            setTitleWarning((prevState) => ({
              ...prevState,
              openTitleWarningModal: false,
            }))
          }
        />
      )}
    </>
  );
};

export default BasicInfoValidation;
